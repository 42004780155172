import React from "react"
import BurgerNav from "../components/burger.js"
import SiteNav from "../components/sitenav.js"
import { Link } from "gatsby"


const ListLink = props => (
  <li>
    {props.to && <Link to={props.to}>{props.children}</Link>}
    {props.href && <a href={props.href} target='_new'>{props.children}</a>}
  </li>
)

const Navigation = props => (
  <div className="block">


    <div className=" nav">
      <div className="sitetitle">
        <Link to="/"><h3>Anna Kovner</h3></Link>
      </div>




      <SiteNav >

       
        <ListLink to="/articles/">Publications</ListLink>
        <ListLink to="/working-papers/">Working Papers</ListLink>
        <ListLink to="/blogs/">Blogs</ListLink>
        <ListLink to="/cv/">CV</ListLink>
       
     
       


      </SiteNav>
      <BurgerNav>
        <ul className="">
      
        <ListLink to="/articles/">Publications</ListLink>
        <ListLink to="/working-papers/">Working Papers</ListLink>
        <ListLink to="/blogs/">Blogs</ListLink>
        <ListLink to="/cv/">CV</ListLink>
        
       

        </ul>
      </BurgerNav>
    </div>
  </div>


)

export default ({ children, footer, title = "", headerImageData = false, headerContent }) => (
  <div>
    <header id="header">
      {/* {(headerImageData !== null && <BackgroundImage
      Tag="div"
      className={"header"}
      fluid={headerImageData}
      backgroundColor={`#222222`}
      >
        <Navigation></Navigation>
        {(title !== "" && <div className="block"><h1 className="title" dangerouslySetInnerHTML={{ __html: title }} /></div>)}
        {headerContent}
      </BackgroundImage>)} */}

      {/* {(headerImageData === null &&  */}
      <div className="header">
        <Navigation></Navigation>
          {headerContent}
      </div>
        {/* )} */}


    </header>

    <div id="content">{children}</div>
    <footer>

      <div className="block">
      
        <div dangerouslySetInnerHTML={{ __html: footer.content }} />
      </div>
    </footer>
  </div>
)
