import React from "react"



const SiteNav = (props) => (
  <ul className={`sitenav ${props.pinned && 'pinned'}`}>
    {props.children}
  </ul>
)



export default SiteNav